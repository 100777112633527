<template>
  <product-options-list />
</template>
<script>
import ProductOptionsList from '@/components/Catalog/ProductOptions/ProductOptionsList'

export default {
  name: 'CatalogProductOptions',
  components: {
    ProductOptionsList
  }
}
</script>
