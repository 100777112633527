<template>
  <div class="ProductOptionEditDialog">
    <v-dialog v-model="optionEditModal" persistent width="800px" @keydown.esc="closeModal" @keydown.enter="submit">
      <v-card>
        <v-card-title class="headline">
          {{ mode === 'new' ? $t('Create Option') : $t('Edit Option') }}
        </v-card-title>
        <v-card-text>
          <v-progress-linear v-if="loading" indeterminate color="blue" class="mb-0" />
          <v-container v-else>
            <v-row>
              <v-form class="w-100" ref="customOption">
                <v-col cols="12">
                  <h3 v-if="!option.type_option">{{ $t('Select an option type') }}</h3>
                  <v-select v-model="option.type_option" :rules="[v => !!v || $t('Type option is required')]"  :validate="false" :label="$t('Type *')" :items="typeOption" item-text="name" item-value="code" required />
                </v-col>
                <div class="option-details" v-if="option.type_option">
                  <v-col cols="12">
                    <v-text-field v-model="option.name" :rules="[v => !!v || $t('Name is required')]" :validate="false" :label="$t('Name *')" required />
                  </v-col>
                  <v-col cols="12"  v-if="option.type_option === 'packaging'">
                    <v-text-field v-model="option.price" :rules="[v => (v && v > 0) || $t('Price is required')]" :validate="false" :label="$t('Price *')" required />
                  </v-col>
                  <v-col cols="12">
                    <v-text-field :disabled="mode !== 'new'" v-model="option.sku" :rules="[v => (v && !!v.length) || $t('SKU is required')]" :prefix="mode !== 'new' ? '' :skuPref" label="SKU *" required />
                  </v-col>
                  <v-col cols="12" sm="9" v-if="option.type_option !== 'packaging'">
                    <v-select v-model="option.type" :label="$t('Display as *')" :rules="[v => !!v || $t('Type is required')]" :items="allowedTypes" item-text="name" item-value="code" required />
                  </v-col>
                  <v-col cols="12" sm="3" v-if="option.type_option !== 'packaging'">
                    <v-switch v-model="option.is_required" :label="$t('Is Required')" color="orange" inset flat hide-details />
                  </v-col>
                  <v-col class="pt-0" cols="12" v-if="option.type === 'checkbox' && option.type_option !== 'packaging' && option.types && option.types.length < 2">
                    <p class="packaging-message">{{ $t("Обов'язкова опція з відображенням checkbox і лише одним варіантом для вибору не буде виведена для користувача, і при додаванні у кошик товар буде автоматично додано з урахуванням цієї опції.") }}</p>
                  </v-col>
                  <v-expansion-panels v-model="typesExpanded" focusable v-if="option.type_option !== 'packaging'">
                    <v-expansion-panel>
                      <v-expansion-panel-header>{{ $t('Option Types') }}</v-expansion-panel-header>
                      <v-expansion-panel-content class="option-types">
                        <types-section v-if="typesExpanded === 0" :types="option.types" :skuPref="skuPrefTypes" :type="mode" @add-new="addNewOptionType"/>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </div>
              </v-form>
            </v-row>
          </v-container>
          <small>{{ $t('* indicates required field') }}</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="submit" :loading="saveInProgress" class="white--text mr-4 my-3" color="primary" depressed text>
            {{ $t('Save') }}
          </v-btn>
          <v-btn  @click="closeModal" class="mr-2 mt-0 white--text" color="red" depressed text>
            {{ $t('Close') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import TypesSection from '@/components/Catalog/ProductOptions/TypesSection'

export default {
  name: 'ProductOptionEditModal',
  components: {
    TypesSection
  },
  data () {
    return {
      optionEditModal: true,
      loading: false,
      saveInProgress: false,
      option: {},
      allowedTypes: [
        { name: 'Checkbox', code: 'checkbox' },
        { name: 'Drop-down', code: 'drop_down' }
      ],
      typeOption: [
        { name: 'Пакування', code: 'packaging' },
        { name: 'Додатки', code: 'addition' },
        { name: 'Інше', code: 'other' },
      ],
      typesExpanded: false,
      type_option: ''
    }
  },
  props: {
    optionId: {
      type: Number,
      required: true
    },
    mode: {
      type: String,
      default: () => 'edit'
    }
  },
  computed: {
    ...mapGetters({
      token: 'user/getAccessToken',
      partner: 'user/getUserPartner'
    }),
    skuPref () {
      return `${this.partner.sku_prefix}-${this.option.type_option === 'packaging'? 'PAC' : 'OPT'}-`
    },
    skuPrefTypes () {
      return this.mode === 'new' ? `${this.skuPref}-${this.option.sku}` : this.option.sku
    }
  },
  methods: {
    ...mapActions({
      loadOption: 'catalog/product_option/load',
      updateOption: 'catalog/product_option/update',
      createOption: 'catalog/product_option/create'
    }),
    openDialog () {
      this.optionEditModal = true
    },
    closeModal () {
      this.optionEditModal = false
      this.option = {}
      this.$bus.$emit('catalog-product-option-edit', null)
      this.$bus.$emit('catalog-product-option-new', false)
      this.toggleBodyScroll(false);
    },
    submit () {
      if (!this.$refs.customOption.validate()) return
      if (this.mode === 'edit') {
        this.save()
      } else {
        this.create()
      }
    },
    save () {
      if (this.option.type_option === 'packaging') {
        this.option.is_required = true
        this.option.types[0].price = this.option.price
      } else if (!this.option.type_option) return
      this.saveInProgress = true
      let option = this.option
      this.updateOption({ token: this.token, option_id: this.option.id, option })
        .then(result => {
          this.option = result.option
          this.$bus.$emit('catalog-product-option-update', { product_id: this.option.id, product: this.option })
        })
        .finally(() => {
          this.saveInProgress = false
          this.closeModal()
        })
    },
    create () {
      if (!this.option.type_option) return
      this.saveInProgress = true
      let option = this.option
      this.createOption({ token: this.token, option })
        .then(result => {
          this.$emit('created', result.option)
          this.$bus.$emit('catalog-product-option-new', false)
        })
        .finally(() => {
          this.saveInProgress = false
          this.closeModal()
        })
    },
    addNewOptionType () {
      this.option.types.push({
        option_id: this.option.id,
        name: '',
        sku: '',
        price: 0,
        position: 0,
        mode: 'new',
        user_id: this.partner.user_id
      })
    },
    optionTypeEdit ({ type }) {
      this.option.types.filter(t => t.mode === 'edit').forEach(t => {
        t.mode = 'view'
      })
      let typeToEdit = this.option.types.find(t => t.sku === type.sku),
        typeIdx = this.option.types.findIndex(t => t.sku === type.sku)
      typeToEdit.mode = 'edit'
      this.option.types.splice(typeIdx, 1, typeToEdit)
    },
    optionTypeUpdate ({ type, newType }) {
      this.option.types.filter(t => t.mode === 'edit').forEach(t => {
        t.mode = 'view'
      })
      let typeIdx = this.option.types.indexOf(type)
      this.option.types.splice(typeIdx, 1, newType)
    },
    toggleBodyScroll(disable) {
      document.documentElement.style.overflowY = disable ? 'hidden' : 'auto';
    }
  },
   watch: {
     'option.type_option' () {
       if (this.option.type_option !== 'packaging' && this.option.types.length < 2) {
         this.typesExpanded = 0
       }
     }
   },
  mounted () {
    if (this.mode === 'edit') {
      this.loading = true
      this.loadOption({ token: this.token, option_id: this.optionId })
        .then(result => {
          this.option = result.option
          if (this.option && this.option.types.length) this.option.types.forEach(t => t.mode = 'view')
        })
        .finally(() => {
          this.loading = false
        })
    } else {
      this.option = {
        name: '',
        sku: '',
        type: 'checkbox',
        is_required: false,
        types: []
      }
    }
    this.toggleBodyScroll(true)
    this.$bus.$on('option-type-edit', this.optionTypeEdit)
    this.$bus.$on('option-type-update', this.optionTypeUpdate)
  },
  beforeDestroy() {
    this.$bus.$off('option-type-edit', this.optionTypeEdit)
    this.$bus.$off('option-type-update', this.optionTypeUpdate)
  }
}
</script>
<style>
.option-types .v-expansion-panel-content__wrap {
  padding: 0;
}
</style>
<style lang="scss" scoped>
.packaging-message {
  color: #ff0000;
  margin: 0;
}
.w-100 {
  width: 100%;
}
.option-details {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex: 1 1 auto;
}
</style>
