<template>
  <tr class="option-type-line" style="background-color:#efefef;">
    <td>{{ item.name }}</td>
    <td>{{ item.sku }}</td>
    <td class="item-clickable">
      <span class="font-weight-medium">{{ Math.abs(item.price).toFixed(2) }}</span>
    </td>
    <td class="item-clickable">
      <span class="font-weight-medium">{{ parseInt(item.position) }}</span>
    </td>
    <td>
      <div class="buttons">
        <v-icon @click="edit">mdi-pencil</v-icon>
        <v-icon @click="remove">mdi-delete</v-icon>
      </div>
    </td>
  </tr>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'TypeLine',
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapGetters({
      token: 'user/getAccessToken'
    })
  },
  methods: {
    ...mapActions({
      deleteType: 'catalog/product_option/removeType'
    }),
    edit () {
      this.$bus.$emit('option-type-edit', { type: this.item})
    },
    remove () {
      this.$emit('deleteTypes', this.item.id)
      this.deleteType({ token: this.token, option_id: this.item.option_id, type_id: this.item.id })
    }
  }
}
</script>
<style lang="scss" scoped>
  .option-type-line {
    td {
      padding: 0 15px;
    }
    .buttons {
      display: flex;
      place-content: center;
    }
  }
</style>
