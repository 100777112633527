<template>
  <section>
    <v-layout class="mx-3 mt-3 pb-4">
      <v-flex>
        <product-options-list-table :loading="loading" :product-options="product_options" :pagination="pagination"
                                    @searched="debouncedSearch" @update:options="updateOptions" @removed="removedOption"
        />
        <product-option-edit-modal v-if="optionEdit && !addNew" :option-id="optionEdit" />
        <product-option-edit-modal v-else-if="addNew" :option-id="0" mode="new" @created="created" />
      </v-flex>
    </v-layout>
  </section>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import _ from 'lodash';
import ProductOptionsListTable from '@/components/Catalog/ProductOptions/ProductOptionsListTable'
import ProductOptionEditModal from '@/components/Catalog/ProductOptions/ProductOptionEditModal'

export default {
  name: 'ProductOptionsList',
  components: {
    ProductOptionsListTable,
    ProductOptionEditModal
  },
  data () {
    return {
      optionEdit: false,
      addNew: false,
      loading: false,
      product_options: [],
      pagination: {
        total: 0,
        options: {
          itemsPerPage: 10,
          page: 1
        }
      },
      searchTimeout: null
    }
  },
  computed: {
    ...mapGetters({
      token: 'user/getAccessToken'
    })
  },
  methods: {
    ...mapActions({
      getProductOptions: 'catalog/product_option/getProductOptions'
    }),
    debouncedSearch: _.debounce(function(search) {
      this.search(search);
    }, 500),
    created (option) {
      this.product_options.push(option)
      this.pagination.total++
    },
    updateOptionData({ product_id, product }) {
      this.product_options.forEach(pr => {
        if (pr.id === product_id) {
          pr.name = product.name
          pr.position = product.position
        }
      })
    },
    updateOptionEditModal(optionId) {
      this.optionEdit = optionId
    },
    updateAddNewModal (value) {
      this.optionEdit = false
      this.addNew = value
      this.updateOptions(this.pagination.options)
    },
    updateOptions (value) {
      this.loading = true
      const params = {
        ipp: value.itemsPerPage,
        page: value.page,
      };
      if (value.search) {
        params.search = value.search;
      }
      if (value.sortBy.length > 0) {
        params.sortBy = value.sortBy[0];
        params.sortDesc = value.sortDesc.length > 0 && value.sortDesc[0] ? 1 : 0;
      }
      this.getProductOptions({ token: this.token, params })
        .then(result => {
          this.product_options = result.data.data
          this.pagination.total = result.data.total
        })
        .finally(() => {
          this.loading = false
        })
    },
    search (value) {
      this.searchTimeout = setTimeout(() => {
        this.processSearch(value)
        clearTimeout(this.searchTimeout)
      }, 500)
    },
    processSearch (value) {
      this.loading = true
      this.getProductOptions({ token: this.token, params: {
        ipp: this.pagination.options.itemsPerPage, page: 1, search: value
      } })
        .then(result => {
          this.product_options = result.data.data
          this.pagination.total = result.data.total
        })
        .finally(() => {
          this.loading = false
        })
    },
    openEditModal () {
      this.$refs.optionEditModal.openDialog()
    },
    removedOption ({ option }) {
      let idx = this.product_options.indexOf(option)
      this.product_options = this.product_options.splice(idx, 1)
    }
  },
  mounted () {
    this.loading = true
    this.getProductOptions({ token: this.token, params: {
      ipp: this.pagination.options.itemsPerPage, page: this.pagination.options.page
    } })
      .then(result => {
        this.product_options = result.data.data
      })
      .finally(() => {
        this.loading = false
      })
    this.$bus.$on('catalog-product-option-update', this.updateOptionData)
    this.$bus.$on('catalog-product-option-edit', this.updateOptionEditModal)
    this.$bus.$on('catalog-product-option-new', this.updateAddNewModal)
  },
  beforeDestroy () {
    this.$bus.$off('catalog-product-option-update', this.updateOptionData)
    this.$bus.$off('catalog-product-option-edit', this.updateOptionEditModal)
    this.$bus.$off('catalog-product-option-new', this.updateAddNewModal)
  }
}
</script>
