<template>
  <tr class="product-option-line">
    <td class="left"></td>
    <td class="left">{{ item.name }}</td>
    <td class="left">{{ $t(item.type_option) }}</td>
    <td class="left">{{ item.position }}</td>
    <td class="buttons">
      <div class="desktop-button">
        <v-btn small depressed class="blue-grey lighten-5 blue-grey--text text--darken-1 mr-3" @click="openProductOptionEditModal">
          {{ $t('Edit') }}</v-btn>
      </div>
      <div class="mobile-button">
        <v-icon @click="openProductOptionEditModal" color="blue">mdi-pencil</v-icon>
      </div>
    </td>
  </tr>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'ProductOptionsListTableLine',
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      editPrice: false,
      newPrice: null,
      priceLoading: false,
      status: 0,
      statusLoading: false
    }
  },
  computed: {
    ...mapGetters({
      token: 'user/getAccessToken'
    })
  },
  methods: {
    ...mapActions({
      updateProduct: 'catalog/product/update',
      removeOption: 'catalog/product_option/remove'
    }),
    updatePrice () {
      this.priceLoading = true
      this.updateProduct({ token: this.token, product_id: this.item.entity_id, product: { price: this.newPrice } })
        .then(result => {
          this.editPrice = false
          this.$bus.$emit('catalog-product-update', { product_id: this.item.entity_id, product: result.product })
        })
        .finally(() => {
          this.priceLoading = false
        })
    },
    updateStatus () {
      this.statusLoading = true
      this.updateProduct({ token: this.token, product_id: this.item.entity_id, product: { status: this.status } })
        .then(result => {
          this.$bus.$emit('catalog-product-option-update', { product_id: this.item.entity_id, product: result.product })
        })
        .finally(() => {
          this.statusLoading = false
        })
    },
    openPriceEdit () {
      this.newPrice = Math.abs(this.item.price).toFixed(2)
      this.editPrice = true
    },
    openProductOptionEditModal () {
      this.$bus.$emit('catalog-product-option-edit', this.item.id)
    },
    removeProductOption () {
      this.removeOption({ token: this.token, option_id: this.item.id })
        .then(() => {
          this.$emit('removed', { option: this.item })
        })
    }
  }
}
</script>
<style lang="scss" scoped>
.buttons {
  text-align: left;
}
.left {
  text-align: left;
}
.desktop-button {
  display: block;
  @media (max-width: 600px) {
    display: none;
  }
}
.mobile-button {
  display: none;
  @media (max-width: 600px) {
    display: block;
  }
}
</style>
<style scoped>
.item-clickable {
  justify-content: flex-end;
  margin: 0;
  cursor: pointer;
}

.product-option-line {
  height: 45px;
  td {
    padding: 0px 16px;
  }
}

.product-option-line:hover {
  background: #eeeeee;
  cursor: pointer;
}

.column-edit {
  max-width: 135px;
}

.status-switch {
  margin: 0;
  padding: 0;
}
</style>
