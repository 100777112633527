<template>
  <section>
    <v-card>
      <div id="scroll-target" class="scroll-y">
        <v-card-title>
          <v-btn color="primary" dark class="ml-2" @click="addItem">{{ $t('Add version option') }}</v-btn>
        </v-card-title>
        <v-data-table :headers="headers" :items="types" item-key="id" width="100"
                      class="elevation-1 orders" :footer-props="{'items-per-page-text':$t('Rows per page:')}"
        >
          <template v-slot:body="{ items }">
            <template v-for="(item, key) in items">
              <type-line v-if="item && item.hasOwnProperty('mode') && (!item.mode || item.mode === 'view')" :item="item" :key="`${key}-${item.mode}`" @deleteTypes="deleteTypes"/>
              <edit-type-line v-else @deleteTypes="deleteTypes" :type="type" :item="item" :skuPref="skuPref" :key="`${key}-${item.mode}`" @removeNewOption="removeNewOption"/>
            </template>
          </template>
        </v-data-table>
      </div>
    </v-card>
  </section>
</template>
<script>
import TypeLine from '@/components/Catalog/ProductOptions/TypesSection/TypeLine'
import EditTypeLine from '@/components/Catalog/ProductOptions/TypesSection/EditTypeLine'

export default {
  name: 'TypesSection',
  components: {
    TypeLine,
    EditTypeLine
  },
  props: {
    types: {
      type: Array,
      required: true
    },
    type: {
      type: String,
      default: 'edit'
    },
    skuPref: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      search: '',
      headers: [
        { text: this.$t('Name'), align: 'left', width: '20%', value: 'name' },
        { text: this.$t('SKU'), align: 'left', width: '40%', value: 'sku' },
        { text: this.$t('Price'), align: 'left', width: '10%', sortable: false, value: 'price' },
        { text: this.$t('Sort'), align: 'left', width: '5%', sortable: false, value: 'position' },
        { text: this.$t(''), align: 'left', width: '5%', sortable: false, value: '' }
      ]
    }
  },
  methods: {
    addItem () {
      this.$emit('add-new')
    },
    submit () {

    },
    remove () {

    },
    deleteTypes (id) {
      this.types.forEach((type, key) => {
          if (type.id === id) {
            this.types.splice(key, 1)
        }
      })
    },
    removeNewOption (item) {
      this.types.forEach((i, key) => {
        if (item === i) {
          this.types.splice(key, 1)
        }
      })
    }
  }
}
</script>
<style>
.option-type-line, .option-type-edit-line {
  height: 45px;
}
</style>
