<template>
  <tr class="option-type-edit-line">
    <td class="pl-2 mob-name-option">
      <v-text-field v-model="item.name" :rules="[v => !!v || $t('Name is required')]" class="column-edit" dense />
    </td>
    <td>
      <v-flex v-if="item && item.hasOwnProperty('mode') && item.mode === 'edit'">
        {{ item.sku }}
      </v-flex>
      <v-flex v-else>
        <v-text-field  @focusin="show = true" @focusout="show = false" v-model="item.sku" :rules="[v => !!v || $t('SKU is required')]" class="column-edit" dense/>
        <div class="tooltip" v-if="show">
          {{skuPref}}
        </div>
      </v-flex>
    </td>
    <td>
      <v-text-field v-model="item.price" class="column-edit" dense />
    </td>
    <td>
      <v-text-field v-model="item.position" class="column-edit" dense />
    </td>

    <td>
      <div class="buttons">
        <v-icon @click="save" v-if="item.mode && item.mode !== 'new'">mdi-check</v-icon>
        <v-icon @click="remove" :style="{'padding-left': !item.mode || item.mode == 'new' ? '10px' : '0px'}">mdi-delete</v-icon>
      </div>
    </td>
  </tr>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'EditTypeLine',
  props: {
    item: {
      type: Object,
      required: true
    },
    type: {
      type: String,
      default: 'edit'
    },
    skuPref: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      name: '',
      sku: '',
      price: 0,
      price_type: 'fixed',
      position: 0,
      show: false
    }
  },
  computed: {
    ...mapGetters({
      token: 'user/getAccessToken'
    })
  },
  methods: {
    ...mapActions({
      create: 'catalog/product_option/createType',
      update: 'catalog/product_option/updateType',
      deleteType: 'catalog/product_option/removeType'
    }),
    async save () {
      let type = {
        name: this.name,
        sku: this.sku,
        price: this.price,
        position: this.position
      }
      if (this.item.mode) {
        if (this.item.mode === 'new') {
          await this.create({ token: this.token, type: this.item, option_id: this.item.option_id })
            .then(result => {
              if (result.type) {
                type = result.type
                type.mode = 'view'
              }
            })
        } else if (this.item.mode === 'edit') {
          this.item.price_type = this.price_type
          await this.update({ token: this.token, type: this.item, option_id: this.item.option_id, type_id: this.item.id })
            .then(result => {
              type = result.type
              type.mode = 'view'
            })
        }
        this.$bus.$emit('option-type-update', { type: this.item, newType: type })
        return
      }
      throw new Error('Unknown mode set for type item')
    },
    remove () {
      if (this.item.id) {
        this.$emit('deleteTypes', this.item.id)
        this.deleteType({token: this.token, option_id: this.item.option_id, type_id: this.item.id})
      } else {
        this.$emit('removeNewOption', this.item)
      }
    }
  },
  mounted () {
    this.name = this.item.name
    this.sku = this.item.sku
    this.price = this.item.price
    this.position = this.item.position
  }
}
</script>
<style lang="scss" scoped>
.column-edit {
  //max-width: 150px;
}
.desktop-button {
  display: flex;
  @media (max-width: 600px) {
    display: none;
  }
}
.mobile-button {
  display: none;
  @media (max-width: 600px) {
    display: flex;
  }
}
.mob-name-option {
  @media (max-width: 600px) {
    width: 28%;
  }
}
.buttons {
  display: flex;
  place-content: center;
}
.relative {
  position: relative;
}
.tooltip {
  position: absolute;
  top: -10px;
  background: #bdbdbd;
  padding: 5px;
  color: #fff;
  border-radius: 3px;
}
.option-type-edit-line {
  td {
    padding: 0px 5px;
  }
}
</style>
